import { Col, Card, CardBody, CardTitle, CardText } from "reactstrap";

function Cards(props) {
    return (
        <Col md="3">
            <Card>
                {props.image != null &&
                    <img
                        alt="..."
                        className="img-raised"
                        src={props.image}
                    />
                }{props.image == null &&
                    <img
                        alt="..."
                        className="img-raised"
                        src={require("src/assets/img/no-image-new.png")}
                    />
                }
                <CardBody>
                    <CardTitle tag="h4" className="font-weight-bold">{props.title}</CardTitle>
                    <CardText>
                        <p>{props.desc}</p>
                    </CardText>
                    <CardText>
                        <small className="text-muted">
                            {props.role}
                        </small>
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    );
}

export default Cards;
