import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";

export default function Enjoy() {
  const carouselItems = [
    {
      src: require("src/assets/img/badminton.jpg"),
      altText: "Slide 1",
      caption: "Badminton"
    },
    {
      src: require("src/assets/img/games.jpg"),
      altText: "Slide 2",
      caption: "Play Games"
    },
    {
      src: require("src/assets/img/vegan.jpg"),
      altText: "Slide 3",
      caption: "Stocks, United States"
    },
    {
      src: require("src/assets/img/research.jpg"),
      altText: "Slide 4",
      caption: "Stocks, United States"
    }
  ];
  return (
    <div className="section">
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <Row className="justify-content-between align-items-center">
              <UncontrolledCarousel items={carouselItems} />
            </Row>
          </Col>
          <Col md="5">
            <h1 className="profile-title text-left">My Enjoy</h1>
            <h5 className="text-on-back">02</h5>
            <p className="profile-description text-left">
              I usually do everyday
            </p>
            <ul>
              <li>I am interested in doing research to develop my knowledge and skills in a particular area. I might do research in technology, environment, health or another topic that interests me.</li>
              <li>I enjoy playing badminton as a sporting activity that provides physical challenge and fitness. You probably have good skills in picking up the ball and hitting it with accuracy, as well as the ability to move quickly and take the right position on the court.</li>
              <li>I choose not to consume animal products or ingredients derived from animals. I may have great concern for the environment, health, or animal welfare, and try to live up to my values</li>
              <li>I enjoy playing games as an entertainment or recreational activity. I may have an interest in different types of games, one of which is video games.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
}