import React from "react";
import { Link } from "react-router-dom";
// import classnames from "classnames";

import PerfectScrollbar from "perfect-scrollbar";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
  CardTitle,
  CardText
} from "reactstrap";

// core components
import "src/assets/css/nucleo-icons.css";
import IndexNavbar from "src/components/Navbars/IndexNavbar.js";
import Footer from "src/components/Footer/Footer.js";
import Enjoy from "./Sections/Enjoy";
import Projects from "./Sections/Projects";
import WorkExperience from "./Sections/WorkExperience";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let ps = null;

function send() {
  window.location.href = "mailto:bimagusta61@gmail.com"
}

export default function ProfilePage() {
  // const [tabs, setTabs] = React.useState(1);
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  }, []);
  return (
    <>
      {/* <IndexNavbar /> */}
      <div className="wrapper">
        {/* <div className="page-header"> */}
        <img
          alt="..."
          className="dots"
          src={require("src/assets/img/dots.png")}
        />
        <img
          alt="..."
          className="path"
          src={require("src/assets/img/path4.png")}
        />
        <div className="section">
          <Container className="align-items-center">
            <Row>
              <Col lg="6" md="6">
                <h1 className="profile-title text-left">Muhammad Bima Gusta</h1>
                <h5 className="text-on-back">01</h5>
                <p className="profile-description">
                  I am a programmer who is used to working from home and comes from Kediri, East Java. I believe that I have exceptional skills and expertise in the field of programming and have become accustomed to working independently from home. This demonstrates my ability to be self-sufficient and disciplined in managing time and tasks.
                </p>
                <div className="btn-wrapper profile pt-3">
                  <Button
                    className="btn-icon btn-round"
                    color="twitter"
                    href="https://twitter.com/iam_bgst"
                    id="tooltip639225725"
                    target="_blank"
                  >
                    <i className="fab fa-brands fa-twitter" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip639225725">
                    Follow us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-round"
                    color="instagram"
                    href="https://www.instagram.com/iam.bgst"
                    id="tooltip982846143"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip982846143">
                    Follow us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-round"
                    color="github"
                    href="https://github.com/iam-bgst"
                    id="tooltip951161185"
                    target="_blank"
                  >
                    <i className="fab fa-github" />
                    {/* <FontAwesomeIcon icon="coffee" /> */}
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip951161185">
                    Follow us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-round"
                    color="linkedin"
                    href="https://www.linkedin.com/in/bima-gusta"
                    id="tooltip951161185"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin" />
                    {/* <FontAwesomeIcon icon="coffee" /> */}
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip951161185">
                    Follow us
                  </UncontrolledTooltip>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid rounded-circle"
                      src="https://avatars.githubusercontent.com/u/27329912?v=4"
                    />
                    <h4 className="title">Backend Developer</h4>
                  </CardHeader>
                  <CardBody>
                    <Col lg="12">
                      <Row>
                        <Label sm="3">Email</Label>
                        <Label sm="9" >bimagusta61@gmail.com</Label>
                      </Row>
                      <Row>
                        <Label sm="3">Phone</Label>
                        <Label sm="9">+6281233593353</Label>
                      </Row>
                      <Row>
                        <Label sm="3">Age</Label>
                        <Label sm="9">23</Label>
                      </Row>

                    </Col>
                    <Button
                      className="btn-simple btn-icon btn-round float-right"
                      color="primary"
                      type="submit"
                      onClick={send}
                    >
                      <i className="tim-icons icon-send" />
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* </div> */}
        <Enjoy />
        <Projects />
        <WorkExperience />
        {/* <Footer /> */}
      </div>
    </>
  );
}
