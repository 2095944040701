import React from "react";
import { Container, Row, Col } from "reactstrap";

export default function WorkExperience() {
  return (
    <div className="section">
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <Row className="justify-content-between align-items-center row-grid">
              <Col className="px-2 py-2" lg="6" sm="12">
                <div style={
                  {
                    'padding-top': '40%'
                  }
                } className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Multec.TC</h4>
                    <p>
                      Jalan Raya, Jongbiru, Gampengrejo, Kediri Regency, <br />
                      East Java 64182, <br />
                      Indonesia
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="px-2 py-2" lg="6" sm="12">
                <div style={
                  {
                    'padding-top': '40%'
                  }
                } className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">PT. Manggala Technology</h4>
                    <p>
                      Jl Raya Papar - Pare , No 469, Tegowangi, Kec. Plemahan, Kabupaten Kediri, <br />
                      Jawa Timur 64155, <br />
                      Indonesia
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="5">
            <h1 className="profile-title text-left">Work Experience</h1>
            <h5 className="text-on-back">04</h5>
            <p className="profile-description text-left">
              <i>"Experience is the best teacher, and my years of work as a programmer have taught me the importance of persistence, problem-solving, and collaboration."</i>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}