import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "src/assets/css/nucleo-icons.css";
import "src/assets/css/fa/all.min.css";
import "src/assets/scss/blk-design-system-react.scss";
import "src/assets/demo/demo.css";

import ProfilePage from "src/views/Profile/ProfilePage.js";
import reportWebVitals from 'src/reportWebVitals';
import Projects from './views/Projects/Projects';
import load from 'little-loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      {/* <Route path="" element={ <ProfilePage  /> */}
      <Route path="/" element={<ProfilePage />} />
      <Route path="/projects" element={<Projects />} />
    </Routes>
  </BrowserRouter>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <Index />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
reportWebVitals();