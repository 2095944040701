
import React from "react";
import { Container, Row } from "reactstrap";
import { createClient } from '@supabase/supabase-js';

import Card from "src/components/Card/Card";

class Projects extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            count:0,
            supabase: createClient('https://jbxkuznzzmsaybpxamhi.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpieGt1em56em1zYXlicHhhbWhpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjMzMzc5MTMsImV4cCI6MTk3ODkxMzkxM30.2Ob4SqCsu1nx3h6Eat1yNXxC2SZ1bABx3REC8WSQ5Bc')
        }
        this.getProject()

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.body.classList.toggle("index-page");
    };
    async getProject() {
        const { data } = await this.state.supabase.from('portofolio').select('*')
        const { _, count } = await this.state.supabase.from('portofolio').select('*',{count:'exact'})
        this.setState({
            data: data,
            count:Math.floor(count /10)*10
        })
    }
    render() {
        return (
            <>
                <div className="wrapper">
                    <div className="page-header header-filter">
                        <div className="squares square1" />
                        <div className="squares square2" />
                        <div className="squares square3" />
                        <div className="squares square4" />
                        <div className="squares square5" />
                        <div className="squares square6" />
                        <div className="squares square7" />
                        <Container>
                            <div className="content-center brand">
                                <h1 className="h1-seo">Projects</h1>
                                <h3 className="d-none d-sm-block">
                                    More than {this.state.count}+ applications that I have worked with for about 4 years.
                                </h3>
                            </div>
                        </Container>
                    </div>
                    <div className="section">
                        <Container className="text-left">
                            <Row style={
                                {
                                    display: '-webkit-inline-box',
                                }
                            } className="justify-content-between">
                                {this.state.data.map(data => (
                                    <Card title={data.app_name} desc={data.desc} role={data.role} image={data.image} />
                                ))}
                            </Row>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
}
export default Projects;