import axios from "axios";
import React from "react";
import { Link } from "react-router-dom"
import { Container, Row, Button, Col, Card, CardTitle, CardBody } from "reactstrap";
import { createClient } from '@supabase/supabase-js';


class Projects extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            years: [],
            totalContribution: 0,
            contrib: [],
            projects: 0
        }
        this.getTotalContribution();
        this.getProjects();
    }
    getYear() {
        axios.defaults.headers.common['Authorization'] = 'Bearer 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'
        const queryGetYear = {
            query: `query {
                    user(login:"iam-bgst") {
                        createdAt
                        contributionsCollection{
                            contributionYears
                        }
                    }
                  }`,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        axios.post('https://api.github.com/graphql', queryGetYear)
            .then((res) => {
                this.setState({
                    years: res.data.data.user.contributionsCollection.contributionYears
                })
            }).catch((err) => {
                console.log(err);
            })
    }
    async getProjects() {
        const supabase = createClient('https://jbxkuznzzmsaybpxamhi.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpieGt1em56em1zYXlicHhhbWhpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjMzMzc5MTMsImV4cCI6MTk3ODkxMzkxM30.2Ob4SqCsu1nx3h6Eat1yNXxC2SZ1bABx3REC8WSQ5Bc')
        const { _, count } = await supabase.from('portofolio').select('*', { count: 'exact' });
        this.setState({
            projects: Math.floor(count / 10) * 10
        })
    }
    getTotalContribution() {

        var year = new Date().getFullYear()
        var yearStart = 2017
        for (let i = yearStart; i <= year; i++) {
            this.state.years.push(i)
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer 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'
        var total = 0
        this.state.years.forEach((year) => {
            const query = {
                query: `query {
                    user(login:"iam-bgst") {
                    createdAt
                    contributionsCollection(from:"${year}-01-01T00:00:00Z",to:"${year}-12-31T23:59:59Z"){
                        contributionCalendar{
                            totalContributions
                        }
                    }
                }
              }`,
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            axios.post('https://api.github.com/graphql', query)
                .then((res) => {
                    total = total + res.data.data.user.contributionsCollection.contributionCalendar.totalContributions
                    this.setState({
                        totalContribution: total
                    })
                })
                .catch((err) => {
                    console.log(err);
                })
        })


    }
    render() {
        return (
            <div className="section">
                <Container>
                    <Row className="justify-content-between">
                        <Col md="5">
                            <h1 className="profile-title text-right">Projects</h1>
                            <h5 className="text-on-back">03</h5>
                            <p className="profile-description text-right">
                                I am passionate about developing new projects and researching the latest technologies related to the programming languages that I master. I have also contributed to Github with a total of (number of contributions) contributions. I believe that I have the skills and knowledge necessary to make meaningful contributions to the company and the team.
                            </p>
                            <div className="btn-wrapper pt-3 text-right">

                                <Button
                                    className="btn-simple"
                                    color="info"
                                    to="/projects"
                                    tag={Link}
                                >
                                    Check it! <i class="fa-solid fa-chevron-right"></i>
                                </Button>
                            </div>
                        </Col>
                        <Col md="6">
                            <Row className="justify-content-between align-items-center row-grid">
                                <Col className="mt-lg-5" md="12">
                                    <Row>
                                        <Col className="px-2 py-2" lg="6" sm="12">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" sm="5">
                                                            <div className="icon-big text-center icon-warning" color="info">
                                                                <i className="tim-icons icon-app text-warning" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <CardTitle tag="p">{this.state.projects}+</CardTitle>
                                                                <p />
                                                                <p className="card-category">Projects</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col className="px-2 py-2" lg="6" sm="12">
                                            <Card className="card-stats upper bg-default">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center icon-warning">
                                                                <i className="tim-icons icon-chart-bar-32 text-white" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <CardTitle tag="p">19</CardTitle>
                                                                <p />
                                                                <p className="card-category">Longest Strikes</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="px-2 py-2" lg="6" sm="12">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center icon-warning">
                                                                <i className="tim-icons icon-coins text-info" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <CardTitle tag="p">{this.state.totalContribution}</CardTitle>
                                                                <p />
                                                                <p className="card-category">Contributions <small className="text-muted">Github</small></p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col className="px-2 py-2" lg="6" sm="12">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center icon-warning">
                                                                <i className="tim-icons icon-user-run text-success" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <CardTitle tag="p">4+ <small className="text-muted">Years</small></CardTitle>
                                                                <p />
                                                                <p className="card-category">Work Experience</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

// export default function Projects() {

//     return (

//     )
// }
export default Projects;